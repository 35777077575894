import React from "react";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";

// importing all the themes
import Accueil from "../themes/theme8.js";
import Appli from "../themes/theme-one";
import Admin from "../themes/theme-three";
import CRM from "../themes/theme-four";
import Ecran from "../themes/theme-five";
import Classeur from "../themes/theme-seven";
import Boite from "../themes/theme-six";

import Confidentialite from "../screens/confidentialite";
import Cgu from "../screens/cgu";

class MyRouts extends React.Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Accueil} />
            <Route exact path="/kehila" component={Appli} />
            <Route exact path="/admin" component={Admin} />
            <Route exact path="/crm" component={CRM} />
            <Route exact path="/louah" component={Ecran} />
            <Route exact path="/classeur" component={Classeur} />
            <Route exact path="/tseda-smart" component={Boite} />
            <Route exact path="/tsedasmart" component={Boite} />
            <Route exact path="/confidentialite" component={Confidentialite} />
            <Route exact path="/cgu" component={Cgu} />
            <Redirect to={"/"}/>
          </Switch>
        </Router>
      </div>
    );
  }
}
export default MyRouts;


/*
         <Route exact path="/theme-two/" component={ThemeTwo} />
            <Route exact path="/theme-three/" component={ThemeThree} />
            <Route exact path="/theme-four/" component={ThemeFour} />
            <Route exact path="/theme-five/" component={ThemeFive} />
            <Route exact path="/theme-six/" component={ThemeSix} />
            <Route exact path="/theme-seven/" component={ThemeSeven} />
            <Route exact path="/blog-details/" component={Main} />
            <Route exact path="/blogs/" component={Blogs} />
 */
