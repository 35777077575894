import React from 'react'
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <div>
            {/* start header section */}
            <header className="header">
                {/* start navbar */}
                <div className="navbar navbar-default navbar-fixed-top">
                    <div className="container">
                        <div className="row">
                            <div className="navbar-header page-scroll">
                                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#myNavbar" style={{marginTop:"25px"}}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="navbar-brand page-scroll" href="/">
                                    <img src={"/img/Kehila/15.png"} alt="logo" style={{height:"60px"}} />
                                </a>
                            </div>

                            {/* Collect the nav links, forms, and other content for toggling */}
                            <div className="navbar-collapse collapse" id="myNavbar">
                                    <ul className="nav navbar-nav navbar-right" style={{margin:"12px 0"}}>
                                        <li className="active"><a href={"/"} className="page-scroll">Accueil</a></li>
                                        <li><a className="page-scroll" href={"/admin"}>Kehila</a></li>
                                        <li><a className="page-scroll" href={"/crm"}>CRM</a></li>
                                        {/*<li><a className="page-scroll" href={"/louah"}>Louah</a></li>*/}
                                        {/*<li><a className="page-scroll" href={"/classeur"}>Classeur</a></li>*/}
                                        <li><a className="page-scroll" href={"/tsedasmart"}>Tsedasmart</a></li>
                                        <li><a className="page-scroll" href="#tarifs">Tarifs</a></li>
                                        <li><a className="page-scroll" href="#contact">Contact</a></li>
                                    </ul>
                            </div>

                        </div>
                    </div>
                </div>
                {/* end navbar */}
            </header>
            {/* end header section */}
        </div>
    )
}


export default Header;
