import React from 'react'
import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";

import {isMobile} from "react-device-detect"


class CustomerSection extends React.Component {

    state={
        data:[],
        loading:true,
    }

    async getData(){
        const req=await fetch("https://api.kehila.syn-assist.fr/v1/public/trust-clients")
        if(req.ok){
            const data=await req.json()
            this.setState({loading:false,data:data})
        }
    }

    componentDidMount() {
        this.getData()
    }

    render() {

        if(this.state.loading){
            return null
        }

        var sliderMobileHeight=isMobile?80:120

        return (
            <div>
                {/* start customers section */}
                <div className="customers-section pt-40">
                    <div className="customers-wrap">
                        <div className="container pb-50">
                            <div className={"row"}>
                                <div className="col-12">
                                    <div className="section-heading text-center">
                                        <h2>Ils nous font Confiance !</h2>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div style={{ height: sliderMobileHeight+20 }}>
                                        <Marquee velocity={10}>
                                            {this.state.data.map((item,index) => (
                                                <img key={index} src={item.logo} style={{height:sliderMobileHeight,marginLeft:50}} />
                                            ))}
                                        </Marquee>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end customers section */}
            </div>
        );
    }
}
export default CustomerSection;
